import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Layout } from "antd";
import { MenuItems } from "./components";
import { Viewer } from "../../lib/types";
import logo from "./assets/zilius-full.png";

import { ReactComponent as Menu } from "../../styles/Menu.svg";
import { ReactComponent as CloseButton } from "../../styles/closeButton.svg";

const { Header } = Layout;

interface Props {
  viewer: Viewer;
  setViewer: (viewer: Viewer) => void;
}

export const AppHeader = ({ viewer, setViewer }: Props) => {
  //console.log("appheader", viewer);

  const [openMenu, setOpenMenu] = useState(false);
  return (
    <Header className="app-header">
      <div className="app-header__logo-search-section">
        <div
          className="app-header_hamburger_icon"
          onClick={() => setOpenMenu((prev) => !prev)}
        >
          <Menu />
        </div>

        {openMenu ? (
          <div className="app-header_mobile">
            <div
              className="app-header_close_icon"
              onClick={() => setOpenMenu((prev) => !prev)}
            >
              <CloseButton />
            </div>
            <div className="app-header-links_mobile">
              <NavLink to="/events" activeClassName="header-active">
                Alerts
              </NavLink>
              {viewer.default ? (
                <>
                  <NavLink
                    to="/simple-reporting"
                    activeClassName="header-active"
                  >
                    Simple reporting
                  </NavLink>
                  <NavLink to="/mihin" activeClassName="header-active">
                    Diretto
                  </NavLink>
                  <NavLink to="/data-overview" activeClassName="header-active">
                    Data overview
                  </NavLink>
                  <NavLink to="/hl7-logging" activeClassName="header-active">
                    HL7 logging
                  </NavLink>
                </>
              ) : null}
            </div>
          </div>
        ) : null}
        <div className="app-header__logo">
          <Link to="/">
            <img src={logo} alt="App logo" />
          </Link>
        </div>
        {viewer.id ? (
          <div className="app-header-links">
            <NavLink to="/events" activeClassName="header-active">
              Alerts
            </NavLink>
            {viewer.default ? (
              <>
                <NavLink to="/simple-reporting" activeClassName="header-active">
                  Simple reporting
                </NavLink>
                <NavLink to="/mihin" activeClassName="header-active">
                  Diretto
                </NavLink>
                <NavLink to="/data-overview" activeClassName="header-active">
                  Data overview
                </NavLink>
                <NavLink to="/hl7-logging" activeClassName="header-active">
                  HL7 logging
                </NavLink>
              </>
            ) : null}
          </div>
        ) : null}
      </div>
      <div className="app-header__menu-section">
        <MenuItems viewer={viewer} setViewer={setViewer} />
      </div>
    </Header>
  );
};
